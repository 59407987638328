import { getRefreshTokenWithRedirect, isUnAuthorizedError } from 'util/auth';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import {
  ApiError,
  OpenAPI,
  OpenAPIConfig,
  BrowseResponse,
  PathwayXPClient,
  SearchResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';
import store from 'store';
import Constants from '../constants/index';

export const getApiToken = () => {
  const localStorageToken = localStorage.getItem('okta-token-storage');
  const webToken = localStorageToken
    ? JSON.parse(localStorageToken)?.accessToken?.accessToken
    : null;
  const iOSToken = (window as any)?.accessToken;
  const reactNativeWebView = (window as any)?.ReactNativeWebView;
  let androidToken;
  if (
    reactNativeWebView &&
    typeof reactNativeWebView.injectedObjectJson === 'function'
  ) {
    androidToken = JSON.parse(
      (reactNativeWebView as any)?.injectedObjectJson(),
    )?.accessToken;
  }
  return webToken || iOSToken || androidToken;
};

export const xpApiConfig: OpenAPIConfig = {
  ...OpenAPI,
  BASE: Constants.XP_API_BASE_URL,
  HEADERS: {
    'Content-Type': 'application/json',
  },
  TOKEN: () => getApiToken(),
  CREDENTIALS: 'include',
  WITH_CREDENTIALS: true,
};

const pathwayXPApiBaseQuery =
  (): BaseQueryFn<
    {
      sdkFunction: Function;
      data?: object;
    },
    unknown,
    unknown
  > =>
  async ({ sdkFunction, data }) => {
    try {
      const result = await sdkFunction(data);
      return { data: result };
    } catch (error) {
      const err: ApiError = error;
      // if unauthorized, get refresh token and redirect
      if (isUnAuthorizedError(err)) {
        // invalidate Browse api response cache when user refreshes token
        store.dispatch(xpApi.util.invalidateTags(['Browse']));
        await getRefreshTokenWithRedirect();
      }
      return {
        error: {
          status: err?.status,
          data: err?.body || err.message,
        },
      };
    }
  };

const client = new PathwayXPClient(xpApiConfig);

export const xpApi = createApi({
  reducerPath: 'xpApi',
  baseQuery: pathwayXPApiBaseQuery(),
  tagTypes: ['Browse'],
  endpoints: builder => ({
    getBrowseStructure: builder.query<
      BrowseResponse,
      { country: string; language: string }
    >({
      query: ({ country, language }) => ({
        sdkFunction: async args => await client.browse.browseStructure(args),
        data: { country, language },
      }),
      providesTags: ['Browse'],
    }),
    getSearchResults: builder.query<
      SearchResponse,
      {
        query: string;
        country?: string;
        language?: string;
        pageNumber?: number;
      }
    >({
      query: ({ query, country, language, pageNumber }) => ({
        sdkFunction: async args => await client.search.search(args),
        data: { query, country, language, pageNumber },
      }),
    }),
  }),
});

export const { useGetBrowseStructureQuery, useGetSearchResultsQuery } = xpApi;
