import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { getNameFromLanguage, pluralize } from 'util/language';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'i18n/language';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import first from 'lodash/first';
import {
  useGetCategoriesQuery,
  useGetSearchResultsQuery,
  useGetSubCategoriesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import Bugsnag from '@bugsnag/browser';
import GenericError from 'sharedComponents/app/GenericError';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserCountry } from 'store/user/selectors';
import { formatBugsnagErrorMessage } from 'bugsnag';
import StepWizard from 'react-step-wizard';
import { v4 as uuidv4 } from 'uuid';
import CategoryCard from 'containers/Explore/Operations/components/CategoryCard';
import CategorySection from 'containers/Explore/Operations/components/CategorySection';
import SubCategoryAccordion from 'containers/Explore/Operations/components/SubCategoryAccordian';
import SubCategoryDocument from 'containers/Explore/Operations/components/SubCategoryDocument';
import {
  Button,
  Card,
  IconButton,
  LoadingIndicator,
  Modal,
  ModalBody,
  Typography,
} from 'cfa-react-components';
import {
  IconArrowLeft,
  IconSquareRoundedCheckFilled,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import ScrollModalFooter from 'components/ScrollModal/ScrollModalFooter';
import ScrollModalHeader from 'components/ScrollModal/ScrollModalHeader';
import Searchbar from 'components/Searchbar/Searchbar';
import PageHeader from 'components/PageHeader/PageHeader';
import DocumentPreview from 'components/DocumentPreview/DocumentPreview';
import {
  selectSearchResults,
  selectResult,
  selectHasNextPage,
  selectNumFound,
  selectShowingResults,
  selectCurrentPage,
  selectPageSize,
  selectStoredQuery,
  selectQueryId,
} from 'store/search/selectors';
import {
  setQueryId,
  setStoredQuery,
  setSearchResults,
  setShowingResults,
  setResult,
  setHasNextPage,
  setNumFound,
  addSearchResults,
  resetSearchState,
  reduxSearch,
  setCurrentPage,
  setPageSize,
} from 'store/search/slice';
import { useGetSearchResultsQuery as xpAPIUseGetSearchResultsQuery } from 'services/xpApi';

const AddProcedurePopUp = props => {
  const {
    isOpen,
    onClose,
    plan,
    refetch,
    isInSection,
    sectionId,
    onAdminAddProcedure,
    adminAddingProcedure,
  } = props;
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const { data, error } = useGetCategoriesQuery();
  const categories = data?.categories ?? [];
  const featured = data?.featured ?? [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const modalBodyRef = useRef<any>();
  const [categorySelected, setCategorySelected] = useState(null);
  const [activeIndexStep, setActiveIndexStep] = useState(1);
  const [stepWizard, setStepWizard] = useState<any>();
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const [searchTermDisplayed, setSearchTermDisplayed] = useState('');
  const [searchTermQuery, setSearchTermQuery] = useState('');
  const [previewDocument, setPreviewDocument] = useState<any>({});

  const handleAddAdminProcedure = () => {
    onAdminAddProcedure(selectedDocuments);
    setSelectedDocuments([]);
  };

  useEffect(() => {
    dispatch(resetSearchState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTermQuery) {
      stepWizard.goToStep(3);
    }
  }, [searchTermQuery, stepWizard]);

  const onAddProcedure = () => {
    const mappedUuidToSelectedDocuments = selectedDocuments.map(
      selectedDocument => {
        return { ...selectedDocument, id: uuidv4() };
      },
    );
    const newSteps = [...plan.steps, ...mappedUuidToSelectedDocuments];
    const payload = {
      locations: plan.locations,
      checklist: isInSection
        ? {
            ...plan,
            sections: [
              ...plan.sections.map(section =>
                section.id === sectionId
                  ? {
                      ...section,
                      steps: [
                        ...section.steps,
                        ...mappedUuidToSelectedDocuments,
                      ],
                    }
                  : section,
              ),
            ],
          }
        : { ...plan, steps: newSteps },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
        setSelectedDocuments([]);
      });
  };

  const onClearSearchClick = () => {
    setSearchTermDisplayed('');
    searchInputRef.current?.focus();
  };

  const onSearchInputChange = e => {
    const searchTerm = e.target.value;
    searchTerm.length
      ? setSearchTermDisplayed(searchTerm)
      : setSearchTermDisplayed('');
  };

  const onSearchTermSelect = (suggestion, spellingSuggestion) => {
    setActiveIndexStep(2);
    if (spellingSuggestion) {
      setSearchTermDisplayed(spellingSuggestion);
      setSearchTermQuery(spellingSuggestion);
      return;
    }
    if (suggestion) {
      setSearchTermDisplayed(suggestion);
      setSearchTermQuery(suggestion);
      return;
    }
    const searchTerm = searchTermDisplayed?.trim();
    if (searchTerm?.length) {
      setSearchTermDisplayed(searchTerm);
      setSearchTermQuery(searchTerm);
    }
  };

  const currentCount = selectedDocuments.length;

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onBackButtonClick = () => {
    if (searchTermQuery && activeIndexStep === 3) {
      setSearchTermQuery('');
      setSearchTermDisplayed('');
      stepWizard?.goToStep(1);
      setActiveIndexStep(1);
      return;
    }
    if (searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(3);
      setActiveIndexStep(3);
      return;
    }
    if (!searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(2);
      setActiveIndexStep(2);
      return;
    }
    activeIndexStep > 1 ? stepWizard.previousStep() : onClose();
    setActiveIndexStep(stepWizard?.state?.activeStep + 1 ?? 0);
  };

  const handleOnClose = () => {
    onClose();
    dispatch(resetSearchState());
    setSearchTermQuery('');
    setSearchTermDisplayed('');
    setSelectedDocuments([]);
  };

  const handleDocumentClick = ({ id, fileId, versionId, name }) => {
    setPreviewDocument({ id, fileId, versionId, name });
    stepWizard.goToStep(4);
  };

  const isPreviewResource = activeIndexStep === 4;

  return (
    <Modal
      onClose={handleOnClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ScrollModalHeader
        scrollRef={modalBodyRef}
        showCloseButton={!isPreviewResource}
      >
        <ModalHeaderRow>
          {activeIndexStep > 1 ? (
            <IconButton data-testid="BackButton" onClick={onBackButtonClick}>
              <IconArrowLeft />
            </IconButton>
          ) : (
            <HeaderSpacer />
          )}
          {isPreviewResource ? (
            <PreviewHeader>
              <Typography variant="overline2">
                {t('TrainingPlans.preview')}
              </Typography>
              <>{previewDocument?.name}</>
            </PreviewHeader>
          ) : (
            t('Generic.chooseResources')
          )}
          <HeaderSpacer />
        </ModalHeaderRow>
        {!isPreviewResource && (
          <AddProcedureSearchbar
            elevation={1}
            fullWidth
            onChange={onSearchInputChange}
            onClear={onClearSearchClick}
            onSubmit={onSearchTermSelect}
            placeholder={t('TrainingPlans.addResource.placeholder')}
            ref={searchInputRef}
            searchValue={searchTermDisplayed}
            showRecentSearchDropdown
          />
        )}
      </ScrollModalHeader>
      <ModalBody ref={modalBodyRef}>
        <StyledProceduresWrapper $currentStep={activeIndexStep}>
          <StepWizard
            instance={setStepWizard}
            isLazyMount
            onStepChange={({ activeStep }) => setActiveIndexStep(activeStep)}
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            <Step1
              categories={categories}
              featured={featured}
              setCategorySelected={setCategorySelected}
              setSearchTermDisplayed={setSearchTermDisplayed}
              stepWizard={stepWizard}
            />
            <Step2
              categorySelected={categorySelected}
              onDocumentClick={handleDocumentClick}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            <Step3
              handleDocumentClick={handleDocumentClick}
              onSearchTermSelect={onSearchTermSelect}
              searchTermQuery={searchTermQuery}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            <Step4 previewDocument={previewDocument} />
          </StepWizard>
        </StyledProceduresWrapper>
      </ModalBody>
      {!isPreviewResource && (
        <ScrollModalFooter scrollRef={modalBodyRef}>
          <Button
            color="secondary"
            data-testid="addProcedureButton"
            disabled={currentCount === 0}
            onClick={
              adminAddingProcedure ? handleAddAdminProcedure : onAddProcedure
            }
          >
            {t('Button.addXObjects', {
              count: currentCount,
              object:
                currentCount === 1
                  ? t('Generic.resource')
                  : t('Generic.resources'),
            })}
          </Button>
        </ScrollModalFooter>
      )}
    </Modal>
  );
};

const Step1 = props => {
  const {
    setCategorySelected,
    categories,
    featured,
    stepWizard,
    setSearchTermDisplayed,
  } = props;
  return (
    <>
      {featured?.length && (
        <CategorySection data-testid="featuredWrapper" section="featured">
          {featured.map(item => (
            <CategoryCard
              categoryIcon={item.icon}
              categoryId={item.id}
              categoryName={item.name}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
      {categories?.length && (
        <CategorySection data-testid="featuredWrapper" section="Catgories">
          {categories.map(item => (
            <CategoryCard
              categoryIcon={item.icon}
              categoryId={item.id}
              categoryName={item.name}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
    </>
  );
};

const Step2 = props => {
  const {
    categorySelected,
    selectedDocuments,
    setSelectedDocuments,
    onDocumentClick,
  } = props;
  const { data, isFetching } = useGetSubCategoriesQuery(
    categorySelected ? categorySelected : '',
  );
  const categoryName = getNameFromLanguage(data?.name);

  const subcategories = data?.subcategories ?? [];
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      label: t('Browse.categories'),
    },
    {
      label: categoryName ?? '',
    },
  ];

  if (isFetching) {
    return 'Loading...';
  }
  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} title={categoryName} />
      {subcategories?.map((subcategory, index) => (
        <SubCategoryAccordion
          data-testid={`SubcategoryAccordion${index}`}
          key={subcategory?.id ?? index}
          name={getNameFromLanguage(subcategory.name)}
        >
          {subcategory?.subtitles?.map(
            // eslint-disable-next-line no-shadow
            ({ name: sectionName, documents }, index) => (
              <div key={index}>
                <Typography variant="overline3">
                  {getNameFromLanguage(sectionName)}
                </Typography>
                {documents?.map(
                  // eslint-disable-next-line no-shadow
                  (document, index) => (
                    <DocumentContainer key={index}>
                      <SubCategoryDocument
                        icon={
                          getNameFromLanguage(document?.references)?.icon ||
                          'Chickfila'
                        }
                        id={
                          getNameFromLanguage(document?.references)
                            ?.documentId ?? ''
                        }
                        name={
                          getNameFromLanguage(document?.references)?.name ?? ''
                        }
                        onClick={() =>
                          onDocumentClick({
                            versionId: getNameFromLanguage(document?.references)
                              ?.versionId,
                            id: getNameFromLanguage(document?.references)?.id,
                            fileId:
                              getNameFromLanguage(document?.references)
                                ?.fileId ?? document?.id,
                            name: getNameFromLanguage(document?.name),
                          })
                        }
                      />
                      {(() => {
                        const isSelected = selectedDocuments.find(
                          selectedDocument =>
                            selectedDocument.id === document.id,
                        );
                        const onChangeCheckbox = () => {
                          isSelected
                            ? setSelectedDocuments(
                                selectedDocuments.filter(
                                  selectedDocument =>
                                    selectedDocument.id !== document.id,
                                ),
                              )
                            : setSelectedDocuments([
                                ...selectedDocuments,
                                {
                                  ...document,
                                  type: Constants.STEP_TYPES.DOCUMENT,
                                  reference: {
                                    id: document.id,
                                  },
                                },
                              ]);
                        };

                        return (
                          <StyledDocumentCheckbox
                            isSelected={isSelected}
                            onChangeCheckbox={onChangeCheckbox}
                          />
                        );
                      })()}
                    </DocumentContainer>
                  ),
                )}
              </div>
            ),
          )}
        </SubCategoryAccordion>
      ))}
    </>
  );
};

const Step3 = ({
  handleDocumentClick,
  onSearchTermSelect,
  searchTermQuery,
  selectedDocuments,
  setSelectedDocuments,
}) => (
  <SearchResults
    onDocumentClick={handleDocumentClick}
    onSearchTermSelect={onSearchTermSelect}
    searchTermQuery={searchTermQuery}
    selectedDocuments={selectedDocuments}
    setSelectedDocuments={setSelectedDocuments}
  />
);

const Step4 = ({ previewDocument }) => {
  return (
    <DocumentPreview
      fileId={previewDocument?.fileId}
      id={previewDocument?.id}
      versionId={previewDocument?.versionId}
    />
  );
};

const StyledDocumentCheckbox = props => {
  const { isSelected, onChangeCheckbox } = props;
  return (
    <StyledDocumentCheckboxWrapper>
      <StyledProcedureBoxFiled>
        {isSelected ? (
          <StyledIconSquareRoundedCheckFilled
            data-testid="documentCheckboxSelected"
            onClick={onChangeCheckbox}
          />
        ) : (
          <StyledIconSquareRoundedPlus
            data-testid="documentCheckbox"
            onClick={onChangeCheckbox}
          />
        )}
      </StyledProcedureBoxFiled>
    </StyledDocumentCheckboxWrapper>
  );
};

export const SearchResults = props => {
  const {
    searchTermQuery,
    onDocumentClick,
    onSearchTermSelect,
    selectedDocuments,
    setSelectedDocuments,
  } = props;
  const dispatch = useDispatch();
  const selectedCountry: { id: string } = useSelector(selectUserCountry);
  const { t } = useTranslation();
  const queryId: reduxSearch['queryId'] = useSelector(selectQueryId);
  const storedQuery: reduxSearch['query'] = useSelector(selectStoredQuery);
  const searchResults: reduxSearch['searchResults'] =
    useSelector(selectSearchResults);
  const result: reduxSearch['result'] = useSelector(selectResult);
  useSelector(selectSearchResults);
  const hasNextPage: reduxSearch['hasNextPage'] =
    useSelector(selectHasNextPage);
  const currentPage: reduxSearch['currentPage'] =
    useSelector(selectCurrentPage);
  const pageSize: reduxSearch['pageSize'] = useSelector(selectPageSize);
  useSelector(selectPageSize);
  const numFound: reduxSearch['numFound'] = useSelector(selectNumFound);
  const showingResults: reduxSearch['showingResults'] =
    useSelector(selectShowingResults);
  const languageCode = getCurrentLanguage();
  const [misspellingSuggestion, setMisspellingSuggestion] = useState('');

  const maxResults = Constants.MAX_SEARCH_RESULTS;

  if (searchTermQuery !== storedQuery) {
    dispatch(resetSearchState());
    dispatch(setStoredQuery(searchTermQuery));
  }

  const { xpApi: xpApiFeatureFlag } = useFlags();

  const {
    data: xpAPIResult,
    isFetching: xpAPIIsFetching,
    error: xpAPIError,
  } = xpAPIUseGetSearchResultsQuery(
    {
      country: selectedCountry.id,
      language: languageCode,
      pageNumber: currentPage,
      query: searchTermQuery ?? '',
    },
    {
      refetchOnMountOrArgChange: false,
      skip:
        !xpApiFeatureFlag || xpApiFeatureFlag === undefined || !searchTermQuery,
    },
  );

  const {
    data: coreResult,
    isFetching: coreIsFetching,
    error: coreError,
  } = useGetSearchResultsQuery(
    {
      language: languageCode,
      page: 1,
      pageSize: pageSize,
      query: searchTermQuery,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: xpApiFeatureFlag || !searchTermQuery,
    },
  );

  const isFetching = xpAPIIsFetching || coreIsFetching;
  const error = xpAPIError ?? coreError;

  useEffect(() => {
    dispatch(setHasNextPage(searchResults?.length < numFound));
  }, [dispatch, numFound, searchResults?.length]);

  useEffect(() => {
    if (
      xpApiFeatureFlag &&
      !!xpAPIResult &&
      xpAPIResult?.results !== searchResults
    ) {
      if (
        xpAPIResult.page === currentPage &&
        currentPage > 1 &&
        xpAPIResult.queryId !== queryId
      ) {
        if (xpAPIResult.page === currentPage) {
          dispatch(setQueryId(xpAPIResult.queryId));
          dispatch(addSearchResults(xpAPIResult.results));
        }
      } else {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(setSearchResults(xpAPIResult.results));
      }
      setMisspellingSuggestion(first(xpAPIResult.spellingSuggestions));
      dispatch(setResult(xpAPIResult));
    } else if (!xpApiFeatureFlag && !!coreResult) {
      dispatch(setSearchResults(coreResult.results));
      setMisspellingSuggestion(
        first(coreResult.spellCorrectedQueryResults)?.suggestedQueryText,
      );
      dispatch(setResult(coreResult));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreResult, xpAPIResult]);

  useEffect(() => {
    const oldShowingResults = Math.min(
      Math.ceil(pageSize * currentPage),
      maxResults,
    );
    const xpApiShowingResults = Math.min(
      Math.ceil(Constants.DEFAULT_PAGING_SIZE * currentPage),
      maxResults,
    );
    const newShowingResults = !!xpApiFeatureFlag
      ? xpApiShowingResults
      : oldShowingResults;

    dispatch(
      setShowingResults(
        hasNextPage ? newShowingResults : numFound > 0 ? numFound : null,
      ),
    );
  }, [
    currentPage,
    dispatch,
    hasNextPage,
    maxResults,
    numFound,
    pageSize,
    showingResults,
    xpApiFeatureFlag,
  ]);

  useEffect(() => {
    if (result && (!!result.totalResults || !!result.numberFound)) {
      dispatch(
        setNumFound(
          xpApiFeatureFlag
            ? Math.min(result.totalResults, maxResults)
            : Math.min(result.numberFound, maxResults),
        ),
      );
    }
  }, [dispatch, maxResults, result, xpApiFeatureFlag]);

  const fetchNextPage = num => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  return (
    <>
      <LoadingOverlay isOpen={!!isFetching && !(searchResults.length > 0)} />
      {(showingResults || misspellingSuggestion) && !isFetching && (
        <>
          {misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() => onSearchTermSelect(null, misspellingSuggestion)}
              >
                {misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
        </>
      )}
      <StyledResultsText variant="body1">
        {` ${numFound} ${t('Search.numberOfResultsFor')} `}
        {<strong>{searchTermQuery}</strong>}
      </StyledResultsText>
      <div className="list-results">
        {!searchResults?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          searchResults?.map(({ icon, id, documentId, name }) => {
            const newId = xpApiFeatureFlag ? id : documentId;
            return (
              <StyledCard key={newId} title={name}>
                <SubCategoryDocument
                  icon={
                    icon === null || icon === 'placeholder' ? 'Chickfila' : icon
                  }
                  id={newId}
                  name={name}
                  onClick={() => onDocumentClick(document)}
                />
                {(() => {
                  const isSelected = selectedDocuments.find(
                    selectedDocument => selectedDocument.id === newId,
                  );
                  const onChangeCheckbox = () => {
                    isSelected
                      ? setSelectedDocuments(
                          selectedDocuments.filter(selectedDocument => {
                            return selectedDocument.id !== newId;
                          }),
                        )
                      : setSelectedDocuments([
                          ...selectedDocuments,
                          {
                            id: newId,
                            type: Constants.STEP_TYPES.DOCUMENT,
                            reference: {
                              id: newId,
                            },
                            name: {
                              en: languageCode === 'en' ? name : null,
                              es: languageCode === 'es' ? name : null,
                            },
                          },
                        ]);
                  };
                  return (
                    <StyledDocumentCheckbox
                      isSelected={isSelected}
                      onChangeCheckbox={onChangeCheckbox}
                    />
                  );
                })()}
              </StyledCard>
            );
          })
        )}
      </div>
      {result && hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={() =>
            fetchNextPage(
              Math.trunc(showingResults / Constants.DEFAULT_PAGING_SIZE) + 1,
            )
          }
          size="lg"
          variant="filled"
        >
          <>
            {(xpAPIIsFetching || coreIsFetching) && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {showingResults && (
        <Typography variant="body1">
          {t('Search.showingResultsFooter', {
            showingResults,
            numberOfResults: numFound <= 100 ? numFound : '100+',
            resultsText: pluralize(
              numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
          })}
        </Typography>
      )}
    </>
  );
};

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ModalHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSpacer = styled.div`
  width: 24px;
`;

const StyledIconSquareRoundedCheckFilled = styled(IconSquareRoundedCheckFilled)`
  color: ${props => props.theme.semanticColors.success};
  cursor: pointer;
`;

const StyledIconSquareRoundedPlus = styled(IconSquareRoundedPlus)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;
const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;

const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 16px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;

const StyledResultsText = styled(Typography)`
  text-align: left;
`;

const StyledNoResults = styled.div`
  padding: 32px 24px;
`;

const AddProcedureSearchbar = styled(Searchbar)`
  margin-top: 24px;
  margin-bottom: 8px;
  z-index: 5;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledProceduresWrapper = styled.div<any>`
  margin: 16px 0;
  ${({ $currentStep }) =>
    $currentStep === 4 &&
    `height: calc(100% - 32px);
  & div {
    height: 100%;
  }`}
`;

const StyledDocumentCheckboxWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
const StyledProcedureBoxFiled = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 1em;
`;

AddProcedurePopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  isInSection: PropTypes.bool,
  sectionId: PropTypes.string,
  onAdminAddProcedure: PropTypes.func,
  adminAddingProcedure: PropTypes.bool,
};

AddProcedurePopUp.defaultProps = {
  isInSection: false,
  sectionId: '',
  onAdminAddProcedure: () => {},
  adminAddingProcedure: false,
};

Step1.propTypes = {
  setCategorySelected: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  featured: PropTypes.array.isRequired,
  stepWizard: PropTypes.object,
  setSearchTermDisplayed: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  stepWizard: {},
};

Step2.propTypes = {
  categorySelected: PropTypes.string,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
  onDocumentClick: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  categorySelected: '',
};
Step3.propTypes = {
  handleDocumentClick: PropTypes.func.isRequired,
  onSearchTermSelect: PropTypes.func.isRequired,
  searchTermQuery: PropTypes.string.isRequired,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};

Step4.propTypes = {
  previewDocument: PropTypes.object.isRequired,
};

StyledDocumentCheckbox.propTypes = {
  isSelected: PropTypes.object,
  onChangeCheckbox: PropTypes.func.isRequired,
};

StyledDocumentCheckbox.defaultProps = {
  isSelected: null,
};

SearchResults.propTypes = {
  searchTermQuery: PropTypes.string.isRequired,
  onDocumentClick: PropTypes.func.isRequired,
  onSearchTermSelect: PropTypes.func.isRequired,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};

export default AddProcedurePopUp;
