import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { isTrainingAssignedPath } from 'util/url';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconCircleCheckDisabled, IconCircleDisabled } from 'icons';
import {
  IconCircle,
  IconCircleCheck,
  IconAlertTriangleFilled,
  IconDeviceGamepad2,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';
import { useLocation } from 'react-router-dom';

const PlanItemProcedure = ({
  disabled,
  isActive,
  isComplete,
  isMissing,
  language,
  onClick,
  onCompleteToggle,
  statuses,
  step,
  user,
}) => {
  const [isCompleted, setIsCompleted] = useState(isComplete);
  const { t } = useTranslation();
  const userId = user?.adId ?? user?.userId ?? undefined;
  const userStatus = statuses
    ?.find(status => status.userId === userId)
    ?.steps?.find(statusStep => statusStep.stepId === step.id);
  const stepDuration = userStatus?.stepDuration
    ? Math.round(userStatus?.stepDuration / 60)
    : 0;
  const isGame = step?.reference?.type === Constants.DOCUMENT_TYPES.GAME;
  const isDisabled = isMissing || disabled;
  const locationUrl = useLocation();
  const isMyPlansTab = isTrainingAssignedPath(locationUrl);

  useEffect(() => {
    setIsCompleted(isComplete);
  }, [isComplete]);

  return (
    <>
      <TaskOrProcedureBody $isActive={isActive} $isDisabled={isDisabled}>
        {isCompleted ? (
          <IconWrapper
            $isDisabled={isDisabled}
            data-testid="StepStatusIcon"
            onClick={
              !isDisabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!isDisabled ? (
              <StyledIconCircleCheck />
            ) : (
              <IconCircleCheckDisabled />
            )}
          </IconWrapper>
        ) : (
          <IconWrapper
            $isDisabled={isDisabled}
            data-testid="StepStatusIcon"
            onClick={
              !isDisabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!isDisabled ? <StyledIconCircle /> : <DisabledOvalIcon />}
          </IconWrapper>
        )}
        <TypeAndNameWrapper
          $isDisabled={isDisabled}
          data-testid={`PlanStep${step.id}`}
          onClick={() => {
            step?.type === Constants.STEP_TYPES.DOCUMENT && onClick();
          }}
        >
          <Typography data-testid="TaskTitle" variant="overline3">
            {isGame ? t('Generic.game') : t('Generic.resource')}
            {isCompleted &&
              user &&
              ` - ${t('Generic.completedIn')} ${stepDuration} ${t(
                'Generic.mins',
              )}`}
          </Typography>
          <>
            {isMissing ? (
              <>
                <Typography data-testid="TaskOrProcedureName" variant="body1">
                  {/* remove underscores from name */}
                  {getNameFromLanguage(step?.name, language).replace(/_/g, ' ')}
                </Typography>
                <StyledWarningWrapper>
                  <IconAlertTriangleFilled size={16} />
                  <StyledWarningMessage
                    data-testid="TaskOrProcedureName"
                    variant="body1"
                  >
                    {isMyPlansTab
                      ? t('TrainingPlans.noResource')
                      : t('TrainingPlans.noResourceBuild')}
                  </StyledWarningMessage>
                </StyledWarningWrapper>
              </>
            ) : step?.type === Constants.STEP_TYPES.DOCUMENT ? (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(step?.reference?.name, language)}
              </Typography>
            ) : (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(step?.name, language)}
              </Typography>
            )}
          </>
        </TypeAndNameWrapper>
        {isGame && (
          <TaskAndProcedureIcon>
            <IconDeviceGamepad2 />
          </TaskAndProcedureIcon>
        )}
      </TaskOrProcedureBody>
    </>
  );
};

PlanItemProcedure.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  isMissing: PropTypes.bool,
  language: PropTypes.string,
  onClick: PropTypes.func,
  onCompleteToggle: PropTypes.func,
  statuses: PropTypes.array,
  step: PropTypes.object,
  user: PropTypes.object,
};

PlanItemProcedure.defaultProps = {
  disabled: false,
  isActive: false,
  isMissing: false,
  isComplete: false,
  language: '',
  onClick: () => {},
  onCompleteToggle: () => {},
  statuses: [],
  step: null,
  user: null,
};

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
`;

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const DisabledOvalIcon = styled(IconCircleDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ $isDisabled }) => $isDisabled && 'none'};
`;

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const StyledWarningMessage = styled(Typography)`
  margin-left: 0.5em;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const TaskAndProcedureIcon = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  width: 25px;
  margin: 0 5px;
  cursor: pointer;
`;

export default PlanItemProcedure;
